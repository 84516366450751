type ArticleType = {
  hero: URL;
  thumb?: URL;
  title: string;
  date: string;
  author: string;
  views?: string;
  link: string;
  text: string;
  categoryFirstLevel: {
    title: string;
    slug: string;
    subcategories?: { title: string; slug: string } | null;
  };
  categoryFirstLevelLink?: string | null;
  categorySecondLevel?: string | null;
  categorySecondLevelLink?: string | null;
};

type CategoryType = {
  title: string;
  slug: string;
  subcategories?: [{ title: string; slug: string }] | null;
};

type ProductContent = {
  text: string;
};

type FaqType = {
  question: string;
  answer: string;
  linkTitle?: string;
  link?: string;
};

type CampaighNavigationType = {
  title: string;
  anchor: string;
};

export const menu = [
  {
    firstColumn: [
      {
        title: "Vår produkt",
        link: "/livsplanering/",
        childMenu: [
          { title: "Pris och paket", link: "/livsplanering#prisochpaket" },
          {
            title: "Prova på behovsanalys ",
            link: "/livsplanering#behovsanalys",
          },
          {
            title: "Kom igång med din livsplanering ",
            link: "/livsplanering#kom-igang",
          },
          { title: "Vad ingår? ", link: "/livsplanering#vad-ingar" },
          {
            title: "Så här fungerar det",
            link: "/livsplanering#sa-fungerar-det",
          },
        ],
      },
    ],
    secondColumn: [
      {
        title: "Om oss",
        link: "/om-oss/",
        childMenu: [
          { title: "Tidslinje", link: "/om-oss#tidslinje" },
          { title: "Varför Livsplanera?", link: "/om-oss#varfor-livsplanera" },
          {
            title: "Bli en del av Fenix Family",
            link: "/om-oss#jobba-med-oss",
          },
          { title: "Partners", link: "/om-oss#partners" },
          { title: "Våra jurister", link: "/om-oss#vara-jurister" },
        ],
      },
    ],
    thirdColumn: [
      {
        title: "FAQ",
        link: "/faq/",
      },
      {
        title: "artiklar",
        link: "/artiklar/",
      },
      {
        title: "Logga in",
        link: "/login/",
      },
    ],
  },
];

export const menuFooter = [
  {
    firstColumn: [
      {
        title: "Om oss",
        link: "/om-oss/",
        childMenu: [
          { title: "Tidslinje", link: "/om-oss#tidslinje" },
          { title: "Varför Livsplanera?", link: "/om-oss#varfor-livsplanera" },
          {
            title: "Bli en del av Fenix Family",
            link: "/om-oss#jobba-med-oss",
          },
        ],
      },
    ],
    secondColumn: [
      {
        title: "Vår produkt",
        link: "/livsplanering/",
        childMenu: [
          { title: "Pris och paket", link: "/livsplanering#prisochpaket" },
          {
            title: "Prova på behovsanalys ",
            link: "/livsplanering#behovsanalys",
          },
          {
            title: "Kom igång med din livsplanering ",
            link: "/livsplanering#kom-igang",
          },
          { title: "Vad ingår? ", link: "/livsplanering#vad-ingar" },
          {
            title: "Så här fungerar det",
            link: "/livsplanering#sa-fungerar-det",
          },
        ],
      },
    ],
    thirdColumn: [
      {
        title: "Kontakta oss",
        link: "/kontakta-oss/",
        childMenu: [
          // { title: "Chatta med oss", link: "/chatta-med-oss/" },
          { title: "Ring oss", link: "/ring-oss/" },
          { title: "Våra jurister", link: "/om-oss#vara-jurister" },
        ],
      },
    ],
  },
];

export const productDetails = [
  {
    icon: new URL("../images/icon-puzzle.svg", import.meta.url),
    title: "FÖRDELAR MED LIVSPLANERING?",
    text: "Du får snabbt, tryggt och enkelt ordning på viktiga juridiska delar av ditt och dina närmastes liv. Här, nu och inför framtiden. Avtal och önskemål som gör att du helhjärtat kan ägna livet åt saker du verkligen tycker om.",
  },
  {
    icon: new URL("../images/icon-deals.svg", import.meta.url),
    title: "ALLA AVTAL DU BEHÖVER",
    text: "Vi hjälper dig att skapa de avtal du vet att du vill ha, och de som du kanske inte ens visste att du behövde. Alla ingår i din prenumeration och kan ändras när och hur ofta du vill ifall din livssituation förändras.",
  },
  {
    icon: new URL("../images/icon-wallet.svg", import.meta.url),
    title: "SPARANDE OCH FÖRSÄKRING",
    text: "I din behovsanalys får du även indikationer på hur du skulle kunna optimera sparande och försäkringar. För att säkra dina önskemål i testamentet kan det till exempel vara klokt att ha en livförsäkring eller en kapitalförsäkring. Boka gärna tid hos någon av våra bank- och försäkringspartners för en genomgång.",
  },
  {
    icon: new URL("../images/icon-heart.svg", import.meta.url),
    title: "EN TRYGGHET FÖR FAMILJEN",
    text: "Vi ser alltför ofta hur otydliga överenskommelser och testamenten leder till slitningar och bråk. Hur tid som skulle kunna ägnas åt sorgearbete, tröst och omtanke om varandra istället alstrar ilska, bitterhet och svårläkta konflikter. Tydliga avtal minskar risken för detta.",
  },
  {
    icon: new URL("../images/icon-cloud.svg", import.meta.url),
    title: "FÖRVARING - FYSISKT OCH DIGITALT",
    text: 'Alla dina avtal förvaras i säkerhet i ditt digitala kassaskåp här på <a class="underline" href="https://fenixfamily.se/">fenixfamily.se</a> Testamentet hjälper vi dig även att förvara fysiskt så att de inte hamnar på villovägar.',
  },
  {
    icon: new URL("../images/icon-shield.svg", import.meta.url),
    title: "ÖVERVAKNING OCH VERKSTÄLLANDE",
    text: "Förutom att förvara ditt testamente så övervakar vi det, och om du vill kan vi även se till att det verkställs. Vår erfarenhet är att testamenten ibland kan hamna fel eller försvinna helt. Därför är det klokt att låta en tredje part som Fenix Family förvara, övervaka och verkställa så att dina önskningar följs som det är tänkt.",
  },
];

export const advisors = [
  {
    image: new URL(
      "../images/advisors/JPG_195x235/Johanna.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../images/advisors/JPG_390x470/Johanna.jpg",
      import.meta.url
    ),
    name: "Johanna Blumenthal",
    title: "Chefsjurist",
  },
  {
    image: new URL("../images/advisors/JPG_195x235/Sanna.jpg", import.meta.url),
    imageRetina: new URL(
      "../images/advisors/JPG_390x470/Sanna.jpg",
      import.meta.url
    ),
    name: "Sanna Wetterin",
    title: "Jurist",
  },
  {
    image: new URL("../images/advisors/JPG_195x235/Erik.jpg", import.meta.url),
    imageRetina: new URL(
      "../images/advisors/JPG_390x470/Erik.jpg",
      import.meta.url
    ),
    name: "Erik Johnsson-Arvids",
    title: "Administratör",
  },
  // {
  //   image: new URL("../images/advisors/JPG_195x235/My.jpg", import.meta.url),
  //   imageRetina: new URL(
  //     "../images/advisors/JPG_390x470/My.jpg",
  //     import.meta.url
  //   ),
  //   name: "My Blomster",
  //   title: "Jurist",
  // },
  {
    image: new URL("../images/advisors/JPG_195x235/Anna.jpg", import.meta.url),
    imageRetina: new URL(
      "../images/advisors/JPG_390x470/Anna.jpg",
      import.meta.url
    ),
    name: "Anna Blomgren",
    title: "Jurist",
  },
  {
    image: new URL("../images/advisors/JPG_195x235/Emmy.jpg", import.meta.url),
    imageRetina: new URL(
      "../images/advisors/JPG_390x470/Emmy.jpg",
      import.meta.url
    ),
    name: "Emmy Forss Hellstrand",
    title: "Jurist",
  },
  // {
  //   image: new URL("../images/advisors/JPG_195x235/Maria.jpg", import.meta.url),
  //   imageRetina: new URL(
  //     "../images/advisors/JPG_390x470/Maria.jpg",
  //     import.meta.url
  //   ),
  //   name: "Maria Antoniadou",
  //   title: "Dödsboförvaltare",
  // },
  {
    image: new URL(
      "../images/advisors/JPG_195x235/Angelica.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../images/advisors/JPG_390x470/Angelica.jpg",
      import.meta.url
    ),
    name: "Angelika Jansson",
    title: "Dödsboförvaltare",
  },
];

export const partners = [
  {
    logo: new URL("../images/bostadsratterna.jpg", import.meta.url),
    title: "Bostadsrätterna",
  },
  {
    logo: new URL("../images/tydliga.jpg", import.meta.url),
    title: "Tydliga_blå_CMYK",
  },
  {
    logo: new URL("../images/SF_Logo_Sekunda.png", import.meta.url),
    title: "SF_Logo_Sekunda",
  },
  {
    logo: new URL("../images/svp_pos_skylt.svg", import.meta.url),
    title: "Svensk Värdepappersservice",
  },
];

export const reviews = [
  {
    title: "Sokol Gjerku, SGJ Capital",
    text: "Nu kan vi erbjuda våra kunder ett större utbud av tjänster och produkter vilket ger oss möjlighet att göra skillnad i våra kunders, och deras familjemedlemmars, liv.\n\n Tillsammans med Fenix Family skapar vi något för våra kunder som vi inte hade kunnat erbjuda på egen hand. Vårt samarbete är väldigt inspirerande!",
  },
  {
    title: "Jörgen Rydén, Skånsk Försäkringsservice AB",
    text: "Jag har fått god input från flera kunder efter genomgången, senast igår fick jag ett sms att detta var ”mycket uppskattat”. Samarbetet med Fenix Family har varit bra både när det gäller själva tjänsten och på ett personligt plan, de har varit extremt lyhörda.",
  },
  {
    title: "Viveca Mellberg, Svensk fastighetsförmedling",
    text: "Så roligt att se att vi har kommit igång... 1 möte har redan resulterat i ett förmedlingsuppdrag. Naturligtvis hoppas vi på att få hjälpa alla kunder att sälja sin bostad, men vi vet att i dagens marknad tar besluten lite längre tid.",
  },
];

export const steps = [
  {
    icon: new URL("../images/bankid-dark.svg", import.meta.url),
    title:
      "Logga in och beskriv din livs- och familjesituation och få våra råd om vad du behöver tänka på.",
  },
  {
    icon: new URL("../images/star.svg", import.meta.url),
    title:
      "Svara på några enkla frågor om dina önskemål kring dina tillgångar, lån och din ekonomi.",
  },
  {
    icon: new URL("../images/file-check.svg", import.meta.url),
    title:
      "Få de juridiska avtal du behöver automatiskt samt tips om finansiella tjänster för att uppfylla dina önskemål.",
  },
];

export const timeline = [
  {
    year: "2004",
    text: "När Johannes mamma går bort upplever han hur komplicerat det är att planera en begravning.",
  },
  {
    year: "2006",
    text: "Efter research av begravningsbranschen inser Mikael att den präglas av höga priser och brist på transparens.",
  },
  {
    year: "2009",
    text: "Charlotte har kontakt med en av Sveriges största begravningsbyråer och upplever en stor avsaknad av kundfokus.",
  },
  {
    year: "2014",
    text: "Charlotte, Mikael och Johannes bestämmer sig för att starta Fenix Begravning.",
  },
  {
    year: "2017",
    text: "Fenix juridik grundas för att kunna hjälpa människor med alla de utmaningar som uppstår när en anhörig går bort.",
  },
  {
    year: "2022",
    text: "Ett nytt Fenix lanseras, med ny grafisk identitet och större fokus på att hjälpa kunderna genom alla aspekter av livet: såväl begravningsplanering som juridik.",
  },
];

export const faq: FaqType[] = [
  {
    question: "Måste jag skriva ett testamente?",
    answer:
      "Nej, det måste du inte. Utan testamente kommer arvet efter dig att fördelas enligt lag till dina närmaste släktingar och/eller din make/maka. Detta kan dock innebära att dina personliga önskemål inte uppfylls. Du kanske vill att en sambo, kompis, idrottsförening eller annan släkting ska ha en del av ditt arv. Då krävs ett testamente.",
  },
  {
    question: "Kan jag testamentera bort allt precis som jag vill?",
    answer:
      "Ja, det kan du. Men det är viktigt att du vet om att ifall du har barn utanför ett nuvarande äktenskap, så har de alltid rätt att få ut sin laglott från din kvarlåtenskap. Laglotten är hälften av vad de hade fått om du inte skrivit ett testamente. Detta betyder att du fritt kan testamentera 100% av din kvarlåtenskap, men har du barn utanför ett nuvarande äktenskap kan de begära jämkning av testamentet så att upp till 50% av det du testamenterat bort istället går till det/dem.",
  },
  {
    question: "Måste jag tala om för någon att jag skrivit ett testamente?",
    answer:
      "Du behöver ha två personer som deltar vid signeringen och skriftligen bevittnar ditt testamente. Dessa två ska vara utomstående på så vis att de inte får påverkas av testamentet eller vara släkt med dig. Du behöver inte delge innehållet i testamentet, men du måste berätta att de är vittnen till ditt testamente. I övrigt behöver du inte tala om det för någon. Det kan också vara en bra idé att placera testamentet på en plats så att dina efterlevande hittar det samt att ha en kopia hos en neutral tredjepart, till exempel hos oss på Fenix Family. Vi kan förvara testamentet säkert, både digitalt och fysiskt, och meddela dina efterlevande när du går bort.",
  },
  {
    question: "Vad händer om jag ändrar mig innan jag går bort?",
    answer:
      "Då kan du skriva ett tillägg till testamentet om det är en mindre justering. Kom ihåg att även tillägg måste följa samma formkrav som ett ordinarie testamente. \n\nVid större justeringar rekommenderar vi att du skriver ett helt nytt testamente som ersätter det gamla.",
  },
  {
    question:
      "Vad händer om jag inte har kvar de tillgångar som jag testamenterar bort när jag avlider?",
    answer:
      'Har du inte kvar specifika tillgångar, så händer ingenting. De kan inte fördelas och ersätts inte med något annat. Vill du vara säker på att någon specifik arvinge erhåller en viss andel, rekommenderar vi att du istället för specifik egendom utger ett universellt förordnande. Ett exempel på detta är "1/4 av min kvarlåtenskap".',
  },
  {
    question: "Varför behöver jag en framtidsfullmakt?",
    answer:
      "Om du skulle bli oförmögen att hantera dina egna intressen, exempelvis om du skulle bli dement, kan den person du utsett fortsätta att ta hand om din ekonomi och person på det sätt du själv skulle ha gjort. Personen företräder dig gentemot bank, myndighet, mäklare etc. En framtidsfullmakt hos en för dig betrodd person är ofta att föredra framför att få en god man tilldelad sig.",
  },
  {
    question: "Vad händer om jag inte har en framtidsfullmakt?",
    answer:
      "I det fall du skulle bli oförmögen att hantera dina egna intressen, exempelvis om du skulle bli dement, kommer i så fall en god man istället utses för att företräda dig. Det blir en person du inte själv valt och processen att utse den goda mannen är tidskrävande. En framtidsfullmakt börjar gälla direkt.",
  },
  {
    question: "Kan jag ha flera fullmaktshavare?",
    answer:
      "Ja. Du kan även välja om de måste agera ihop eller om de kan utföra ärenden var och en för sig, utan den andres godkännande.",
  },
  {
    question: "Behöver fullmaktshavarna veta att jag utsett dem?",
    answer:
      "Det är inget krav att de vet men eftersom det är ett stort ansvar och uppdrag att vara fullmaktshavare, bör de ha blivit informerade och tillfrågade. Så att du kan vara säker på  att de kommer vilja ta sig an uppdraget.",
  },
  {
    question: "Gäller framtidsfullmakten även när jag dött?",
    answer:
      "Det är inte helt glasklart ännu, men praxis pekar på att den kan gälla en tid efter dödsfallet om man skriver in i fullmakten att den ska göra det.",
  },
];

export const faqBlock: FaqType[] = [
  {
    question: "Vad är livsplanering för något?",
    answer:
      "Kanske har du redan allt på plats, kanske har du inte ens tänkt tanken att planera ditt liv lite noggrannare. Hur det än ligger till kan vi hjälpa dig. Att fila på de sista detaljerna eller att göra upp en plan för framtiden. Vi kallar det Livsplanering. Det innebär att vi utifrån ett testamente tar fram en unik livsplan för dig, din familj, dina tillgångar och eventuella skulder. Planen innefattar juridiska avtal, råd om finansiella tjänster och praktiska förberedelser för att du ska få lugn och ro här och nu och i framtiden. Vi förvarar dem sedan säkert, både digitalt och fysiskt, samt övervakar dem så att det blir precis som du önskar när avtalen väl ska verkställas. En livsplanering kan spara dig och de dina mycket besvär, både nu och i framtiden.",
  },
  {
    question: "Måste jag skriva ett testamente?",
    answer:
      "Nej, det måste du inte. Utan testamente kommer arvet efter dig att fördelas enligt lag till dina närmaste släktingar och/eller din make/maka. Detta kan dock innebära att dina personliga önskemål inte uppfylls. Du kanske vill att en sambo, kompis, idrottsförening eller annan släkting ska ha en del av ditt arv. Då krävs ett testamente.",
  },
  {
    question: "Måste jag tala om för någon att jag skrivit ett testamente?",
    answer:
      "Du behöver ha två personer som deltar vid signeringen och skriftligen bevittnar ditt testamente. Dessa två ska vara utomstående på så vis att de inte får påverkas av testamentet eller vara släkt med dig. Du behöver inte delge innehållet i testamentet, men du måste berätta att de är vittnen till ditt testamente. I övrigt behöver du inte tala om det för någon. Det kan också vara en bra idé att placera testamentet på en plats så att dina efterlevande hittar det samt att ha en kopia hos en neutral tredjepart, till exempel hos oss på Fenix Family. Vi kan förvara testamentet säkert, både digitalt och fysiskt, och meddela dina efterlevande när du går bort.",
  },
  {
    question: "Varför behöver jag en framtidsfullmakt?",
    answer:
      "Om du skulle bli oförmögen att hantera dina egna intressen, exempelvis om du skulle bli dement, kan den person du utsett fortsätta att ta hand om din ekonomi och person på det sätt du själv skulle ha gjort. Personen företräder dig gentemot bank, myndighet, mäklare etc. En framtidsfullmakt hos en för dig betrodd person är ofta att föredra framför att få en god man tilldelad sig.",
  },
  {
    question: "Vilka är Fenix Family?",
    answer:
      "Fenix Family föddes ur begravningsbyrån Fenix Begravning som grundades 2014 av Charlotte Runius, Johannes Ekblad och Mikael Novak. De drog snabbt slutsatsen att en närståendes död väcker frågor kring mycket mer än bara själva begravningsceremonin. I livets slutskede ställs ofta ekonomi, juridik och hur man planerat framtiden för sina närmaste på sin spets. Steg ett för Fenix Family blev att erbjuda juridisk expertis i en lättillgänglig digital tjänst. Så att alla som vill och behöver, snabbt och enkelt kan skapa testamenten, framtidsfullmakter och andra viktiga avtal för att göra både nuet och framtiden tydligare. Inte bara för den som skriver avtalen utan även för dennes nära och kära.",
  },
];

export const faqPartners: FaqType[] = [
  {
    question:
      "Vad är det som skiljer Fenix Familys livsplanering från andra aktörer på marknaden inom Legal Tech?",
    answer:
      "I konceptet livsplanering förenas kombinationen av juridik, sparande och försäkringar med fokus på kundens önskemål. Hos Fenix Family erbjuds kunden hjälp med alla viktiga pusselbitar direkt och behöver därmed inte leta efter kompletterande hjälp utanför vårt samarbete. <br/><br/>Livsplanering underlättar inte bara för kunderna utan även för nästkommande generationer. Genom att involvera hela familjen i produkten minskar man risken för missförstånd och konflikter.",
  },
  {
    question:
      "Hur bjuder jag in mina kunder till en livsplanering på bästa sätt och vad ska jag förmedla för att så många som möjligt ska bli intresserade?",
    answer:
      "Du kommer att få tillgång till din egna kundvårdssystem, “Partnerportalen”, där du ges full översikt kring dina kunders livsplaneringsprocess. Från partnerportalen kan du enkelt skicka inbjudningar till dina kunder att påbörja sin livsplanering. Flödet kan med fördel även användas i samband med fysiska kundmöten eller events.  <br/><br/>Livsplanering är en plan för framtiden baserat på kundens livssituation. Planen inkluderar så väl ekonomiska och juridiska aspekter som försäkringsfrågor för att kunden ska kunna njuta av lugn och ro, här och nu och i framtiden. ",
  },
  {
    question:
      "Hur ska jag tänka om mina kunder redan har ett testamente eller andra juridiska avtal som Fenix Family erbjuder?",
    answer:
      "I Sverige saknar 7 av 10 personer ett testamente idag. De få personer som skrivit ett testamente har dessvärre ofta inte sett till att uppdatera det när deras livssituation förändras. Det är dessutom väldigt vanligt att testamenten inte förvaras säkert. <br/><br/>Om din kund redan har ett testamente hjälper livsplaneringen till att kartlägga behovet av att uppdatera befintligt testamente. Detta genom att analysera kundens nuvarande livssituation och önskemål. I tjänsten ingår även att vi på Fenix Family förvarar och bevakar testamentet tryggt och säkert. Kopior av påskrivna avtal finns också tillgängliga i det digitala kassaskåp.<br/><br/>Livsplaneringens kartläggning visar även på behov av andra juridiska avtal som kunden kan ha. Alla avtal kan dessutom uppdateras löpande.",
  },
  {
    question:
      "Vilka andra verktyg, material, videor som Fenix Family tillhandahåller kan jag använda mig av för att maximera effekten?",
    answer:
      "I din partnerportal kommer du ha tillgång till värdefullt material om livsplanering såsom videos, broschyrer, digitala webinars. Fenix Family tillhandahåller även utbildningar och content inom sälj, marknadsföring och effektiv kommunikation för bästa möjliga effekt.",
  },
  {
    question:
      "Vad består Livsplaneringens samlade kundinformationen av och varför är den värdefull för mig som partner?",
    answer:
      "Den samlade informationen i en kunds livsplan består av en komplett bild över skulder, tillgångar, juridiska dokument samt familjeträdet som tydliggör vilka familjemedlemmar som kommer att ta över och vad.<br/><br/>I samband med att kunden skapar juridiska avtal såsom testamente och framtidsfullmakt, får du som partner oftast en mycket mer komplett bild över kundens livssituation vilket kan leda till:<br/> <ul class='list-disc list-inside'><li>Ökad AUM (Assets Under Management).</li><li>I längre utsträckning kunna bibehålla kunder genom att förebygga kapitalflytt.</li><li>Skapa en naturlig och relevant kontakt med arvingar, det vill säga framtida kunder, genom att överbrygga generationer.</li></ul>",
  },
];

export const articles: ArticleType[] = [
  {
    hero: new URL("../images/article/hero_1_1600x960.jpg", import.meta.url),
    thumb: new URL(
      "../images/article/thumb/hero_1_310x220.jpg",
      import.meta.url
    ),
    title: "Fullmakt",
    date: "18 augusti 2023",
    author: "Förnamnet Efternamnsson",
    views: "53928",
    categoryFirstLevel: {
      title: "Livsplanering",
      slug: "livsplanering",
    },
    categorySecondLevel: null,
    categorySecondLevelLink: null,
    text: "En fullmakt är ett juridiskt dokument som ger en person (fullmaktsgivaren), befogenhet att agera å någon annans vägnar, (fullmaktstagaren). Det är ett kraftfullt verktyg som kan underlätta och effektivisera olika livssituationer och transaktioner. Den här artikeln syftar till att ge dig en översikt över vad en fullmakt är, vilket syfte den har, när den kan vara användbar, hur man skriver en fullmakt, vad man bör tänka på och en hel del annat som kan vara relevant.",
    link: "fullmakt",
  },
  {
    hero: new URL("../images/article/hero_2_1600x960.jpg", import.meta.url),
    thumb: new URL(
      "../images/article/thumb/hero_2_310x220.jpg",
      import.meta.url
    ),
    title: "Arvsrätt",
    date: "21 augusti 2023",
    author: "Förnamnet Efternamnsson",
    views: "1012",
    categoryFirstLevel: {
      title: "Arv",
      slug: "arv",
    },
    categorySecondLevel: null,
    categorySecondLevelLink: null,
    text: 'Den svenska arvsrätten styrs av Ärvdabalken. Det är den lag som styr fördelningen av tillgångar efter en persons bortgång. Att förstå lagarna och logiken i den är grundläggande för att du ska kunna var säker på att din egendom fördelas som du vill efter din bortgång. Den här artikeln syftar till att ge en översikt över svensk arvsrätt, bland annat dess historiska bakgrund, införandet av Ärvdabalken, vem som ärver, vad som händer om du inte är gift med din partner, hur barn ärver, arvsklasser, skillnader från andra större västländer, skatter, förskott på arv, vad man ska tänka på vid handläggning av ärenden som påverkas av arvsrätten, en förklaring av termerna "laglott" och "arvslott" och lite till. Allt får inte plats i en artikel (det finns en anledning till att lagboken är tjock) så om du behöver ytterligare vägledning är du varmt välkommen att kontakta oss på Fenix Family.',
    link: "arvsratt",
  },
  {
    hero: new URL("../images/article/hero_3_1600x960.jpg", import.meta.url),
    thumb: new URL(
      "../images/article/thumb/hero_3_310x220.jpg",
      import.meta.url
    ),
    title: "Skriva testamente",
    date: "16 maj 2023",
    author: "Förnamnet Efternamnsson",
    views: "53928",
    categoryFirstLevel: {
      title: "Livsplanering",
      slug: "livsplanering",
      subcategories: { title: "Testamente", slug: "testamente" },
    },
    categorySecondLevel: null,
    categorySecondLevelLink: null,
    text: "Att skriva ett testamente är ett viktigt steg i planeringen av både nutid och framtid. Enkelt förklarat så ger ett testamente dig möjligheten att fördela dina tillgångar som du önskar efter din bortgång. Att ha detta klart kan ge dig lugn och ro här och nu, samtidigt som det skapar rimliga, gemensamma förväntningar och tydlighet för dina närmaste. Det finns dock olika scenarier och utmaningar att ta hänsyn till när man upprättar ett testamente, särskilt i komplexa familjesituationer. Denna artikel ger dig en översikt över viktiga faktorer att tänka på när man skriver ett testamente, vare sig du skriver testamentet själv eller tar hjälp av oss på Fenix Family eller någon annan juridisk specialist. Vi tar bland annat upp scenarier med barn från tidigare förhållanden, en nuvarande partner med barn från tidigare förhållanden, begränsningarna i Ärvdabalken (namnet på den svenska arvslagstiftningen) och livförsäkringens roll för att säkra en efterlevande partners bostad.",
    link: "skriva-testamente",
  },
  {
    hero: new URL("../images/article/hero_4_1600x960.jpg", import.meta.url),
    thumb: new URL(
      "../images/article/thumb/hero_4_310x220.jpg",
      import.meta.url
    ),
    title: "Gåvobrev",
    date: "17 november 2023",
    author: "Nathalie Bertzén",
    views: "796",
    categoryFirstLevel: {
      title: "Livsplanering",
      slug: "livsplanering",
    },
    categorySecondLevel: null,
    categorySecondLevelLink: null,
    text: "Ibland vill man ge bort något utöver det vanliga. En fastighet, en båt eller en större summa pengar. För att skydda både givare och mottagare rekommenderar vi att ni upprättar ett gåvobrev. I vissa fall krävs det att ett gåvobrev upprättas för att gåvan ska bli giltig. I andra fall är det inget krav men kan bespara alla inblandade parter konflikter och huvudbry i framtiden, till exempel när det handlar om förskott på arv.",
    link: "gavobrev",
  },
  {
    hero: new URL("../images/article/hero_5_1600x960.jpg", import.meta.url),
    thumb: new URL(
      "../images/article/thumb/hero_5_310x220.jpg",
      import.meta.url
    ),
    title: "Sambo",
    date: "18 december 2023",
    author: "Nathalie Bertzén",
    views: "496",
    categoryFirstLevel: {
      title: "Relationer",
      slug: "relationer",
    },
    categorySecondLevel: null,
    categorySecondLevelLink: null,
    text: "Sverige väljer allt fler par att flytta ihop och leva tillsammans i ett samboförhållande, men utan att gifta sig. Och visst är det härligt att få bo tillsammans med den du älskar! Men om du lever som sambo bör du dock vara medveten om att det finns juridiska följder och potentiella utmaningar som kommer med samboskapet.",
    link: "sambo",
  },
  {
    hero: new URL("../images/article/hero_6_1600x960.jpg", import.meta.url),
    thumb: new URL(
      "../images/article/thumb/hero_6_310x220.jpg",
      import.meta.url
    ),
    title: "Bodelning",
    date: "18 december 2023",
    author: "Nathalie Bertzén",
    views: "396",
    categoryFirstLevel: {
      title: "Relationer",
      slug: "relationer",
    },
    categorySecondLevel: null,
    categorySecondLevelLink: null,
    text: "Att skiljas eller flytta isär är ofta en stor och traumatisk händelse i livet. Oavsett om skilsmässan eller separationen sker på eget initiativ, partnerns initiativ eller om det är ett gemensamt beslut så innebär det ofta en svår och känslomässig tid. Utöver den emotionella stressen som en skilsmässa eller separation kan medföra finns det även praktiska och svåra juridiska frågor kring exempelvis ekonomi och uppdelning av tillgångar som måste tas itu med.",
    link: "bodelning",
  },
  {
    hero: new URL("../images/article/hero_3_1600x960.jpg", import.meta.url),
    title: "Begravning utan ceremoni",
    date: "16 maj 2023",
    author: "Förnamnet Efternamnsson",
    views: "978",
    categoryFirstLevel: {
      title: "Begravning 5",
      slug: "begravning-5",
    },
    categorySecondLevel: "Begravning utan ceremoni",
    categorySecondLevelLink: "begravning-utan-ceremoni",
    text: "Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska.\n\n Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska",
    link: "begravning-utan-ceremoni",
  },
  {
    hero: new URL("../images/article/hero_3_1600x960.jpg", import.meta.url),
    title: "Kyrklig begravning",
    date: "16 maj 2023",
    author: "Förnamnet Efternamnsson",
    views: "896",
    categoryFirstLevel: {
      title: "Begravning 6",
      slug: "begravning-6",
    },
    categorySecondLevel: "Kyrklig begravning",
    categorySecondLevelLink: "kyrklig-begravning",
    text: "Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska.\n\n Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska",
    link: "kyrklig-begravning",
  },
  {
    hero: new URL("../images/article/hero_3_1600x960.jpg", import.meta.url),
    title: "Borgerlig begravning",
    date: "16 maj 2023",
    author: "Förnamnet Efternamnsson",
    views: "53928",
    categoryFirstLevel: {
      title: "Begravning 6",
      slug: "begravning-6",
    },
    categorySecondLevel: "Borgerlig begravning",
    categorySecondLevelLink: "borgerlig-begravning",
    text: "Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska.\n\n Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska",
    link: "borgerlig-begravning",
  },
  {
    hero: new URL("../images/article/hero_3_1600x960.jpg", import.meta.url),
    title: "Judisk begravning",
    date: "16 maj 2023",
    author: "Förnamnet Efternamnsson",
    views: "53928",
    categoryFirstLevel: {
      title: "Begravning 7",
      slug: "begravning-7",
    },
    categorySecondLevel: "Judisk begravning",
    categorySecondLevelLink: "judisk-begravning",
    text: "Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska.\n\n Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska",
    link: "judisk-begravning",
  },
  {
    hero: new URL("../images/article/hero_3_1600x960.jpg", import.meta.url),
    title: "Ortodox begravning",
    date: "16 maj 2023",
    author: "Förnamnet Efternamnsson",
    views: "53928",
    categoryFirstLevel: {
      title: "Begravning 7",
      slug: "begravning-7",
    },
    categorySecondLevel: "Ortodox begravning",
    categorySecondLevelLink: "ortodox-begravning",
    text: "Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska.\n\n Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska",
    link: "ortodox-begravning",
  },
  {
    hero: new URL("../images/article/hero_3_1600x960.jpg", import.meta.url),
    title: "Jordbegravning",
    date: "16 maj 2023",
    author: "Förnamnet Efternamnsson",
    views: "53928",
    categoryFirstLevel: {
      title: "Begravning 4",
      slug: "begravning-4",
    },
    categorySecondLevel: "Jordbegravning",
    categorySecondLevelLink: "jordbegravning",
    text: "Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska.\n\n Enligt sederna inom islam skall kroppen efter den avlidne begravas utan onödigt dröjsmål, och då helst inom 36 timmar. Det här är något som ofta är svårt att uppnå i Sverige, på grund av att Skatteverket först måste registrera dödsfallet. Men det går att påskynda processen en aning.\n\n Det är alltid en läkare som tillkallas för att konstatera att en person har avlidit. När han eller hon har dödförklarat den avlidne, skriver han ett dödsbevis som ska",
    link: "jordbegravning",
  },
];

export const categories: CategoryType[] = [
  { title: "Arv", slug: "arv" },
  {
    title: "Livsplanering",
    slug: "livsplanering",
    subcategories: [{ title: "Testamente", slug: "testamente" }],
  },
  { title: "Relationer", slug: "relationer" },
];

export const productDefaultList: ProductContent[] = [
  {
    text: "Vi ser till att du har allting du behöver för ett tryggare liv, genom att du svarar på våra frågor en gång.",
  },
  { text: "Leverans av juridiska avtal." },
  {
    text: "Inlogg och förvaring av alla dina avtal i vårt digitala kassaskåp.",
  },
  { text: "Förvaring och övervakning av testamente." },
  { text: "Rådgivning av våra kompetenta jurister." },
  { text: "Förplanering av begravning." },
];

export const productAffiliateList: ProductContent[] = [
  {
    text: "Inventering av din livssituation så att du ska få allt du behöver för en tryggare nutid och framtid.",
  },
  { text: "Leverans av juridiska avtal." },
  {
    text: "Förvaring av och åtkomst till dina avtal i vårt digitala kassaskåp.",
  },
  { text: "Förvaring och övervakning av ditt testamente." },
  { text: "Medskick till dina efterlevande." },
];

export const legalDefaultList: ProductContent[] = [
  {
    text:
      "Vi ser till att du har allting du behöver för ett tryggare liv, \n" +
      "genom att du svarar på våra frågor en gång. ",
  },
  { text: "Leverans av juridiska avtal." },
  {
    text: "Inlogg och förvaring av alla dina avtal i vårt digitala kassaskåp.",
  },
];

export const campaignNavigation: CampaighNavigationType[] = [
  { title: "Underlätta för efterlevande", anchor: "survive" },
  { title: "Våra råd", anchor: "advice" },
  { title: "Fenix begravning", anchor: "fenixbegravning" },
  { title: "Fenix livsplanering", anchor: "livsplanering" },
];
