import { useEffect } from "react";

import { homeRedirectKey, partnerKey, removeFromStorage } from "../storage";

export function usePartnerPackageCleanUp() {
  useEffect(() => {
    removeFromStorage(partnerKey);
    removeFromStorage(homeRedirectKey);
  }, []);
}
